


























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {ResetPasswordData} from "@/modules/auth/store/types";
import {required, sameAs} from "vuelidate/lib/validators";


Component.registerHooks(['validations'])
@Component({
  components: {SygniContainerTitle, SygniCheckbox, SygniLine, SygniRadio, SygniRoundedButton, SygniInput}
})
export default class ResetPassword extends Vue {

  password: string = '';
  repeatPassword: string = '';

  async resetPassword(){
    const resetPasswordData: ResetPasswordData = { plainPassword: this.password, token: this.$route.params?.id };
    this.$v.$touch();
    if(!this.$v.$error) {
      try{
        await this.$store.dispatch('auth/resetPassword', resetPasswordData);
        this.$notify({
          type: 'success',
          title: 'Password has been changed'
        });
        await this.$router.push({name: 'login'});
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error'
        });
      }
      this.password = '';
      this.repeatPassword = '';
      this.$v.$reset();
    }
  }

  validations (){
    return  {
      password: {required, strongPassword: (value: string) => {
          return Boolean(value?.match('^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$'));
        }},
      repeatPassword: {required, sameAsPassword: sameAs('password')},
    }
  }
}
